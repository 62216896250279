import service from '../util/service'
import axios from 'axios';
import { BASE_URL } from '../util/service';
import qs from 'querystring';

export const PATH_LOGIN = "/liang/backend/login/";
const PATH_LOGOUT = "/liang/backend/logout/";

const PATH_MERCHANT = "/liang/merchant/list/";
const PATH_ORDERNUM_ADD = "/liang/merchant/ordernum/add/";

const PATH_ACTIVECODE_CREATE = "/liang/activecode/create/";

const PATH_ORDER_LIST = "/liang/order/list/";
const PATH_ORDER_ADD = "/liang/order/add/";
export const PATH_ORDER_BULK_ADD = "/liang/order/bulk/add/";

const PATH_MERCHANT_ORDER_REMAINNUM = "/liang/order/remain/num/";
const PATH_MERCHANT_ORDER_LIST = "liang/merchant/order/list/";
const PATH_USER_ACTIVED_LIST = "liang/backend/user/actived/";
const PATH_USER_LIST = "liang/backend/user/list/";
const PATH_STATISTICS_INFO = "liang/backend/statistics/data/";
const PATH_BUILDING_LIST = "liang/backend/house/list/";


const PATH_ADD_TW_USER = "liang/backend/tw/user/add/";
const PATH_CHANGE_TW_USER_PWD = "liang/backend/tw/change/password/";
const PATH_TW_USER_LIST = "liang/backend/tw/user/list/";
const PATH_TW_ACTIVE_USER = "liang/backend/tw/active/user/";

const PATH_USER_ACTIVE_RECORDS = "liang/backend/user/activerecord/";

const api = {}

api.login = (mobile, password) => {

    return service({
        url: PATH_LOGIN,
        data: {
            mobile,
            password
        },
        method: 'POST'
    })
}

api.logout = () => {
    return service({
        method: 'POST',
        url: PATH_LOGOUT
    });
}

api.merchantList = () => {
    return service({
        url: PATH_MERCHANT,
    })
}

api.orderAdd = (order_num, merchant_id) => {
    return service({
        url: PATH_ORDERNUM_ADD,
        data: {
            order_num,
            merchant_id
        },
        method: 'POST'
    });
}

api.activecodeCreate = (amount, merchant_id, expired_day, effect_day) => {
    return axios({
        url: BASE_URL + PATH_ACTIVECODE_CREATE,
        method: 'POST',
        responseType: 'blob',
        data: qs.stringify({
            amount,
            merchant_id,
            expired_day,
            effect_day
        })
    });
}

api.ordernumAdd = (order_num, merchant_id) => {
    return service({
        url: PATH_ORDERNUM_ADD,
        method: 'POST',
        data: {
            order_num,
            merchant_id
        }
    })
}

api.orderList = (page_size, page) => {
    return service({
        url: PATH_ORDER_LIST,
        params: {
            page_size,
            page
        }
    });
}

api.orderAdd = (order_no) => {
    return service({
        url: PATH_ORDER_ADD,
        method: 'POST',
        data: {
            order_no
        }
    });
}

api.merchantOrderRemainNum = () => {
    return service({
        url: PATH_MERCHANT_ORDER_REMAINNUM
    })
}

api.uploadOrderFile = (file) => {
    let formData = new FormData();
    formData.append('file', file)
    return axios.post(BASE_URL + PATH_ORDER_BULK_ADD, formData, {
        'Content-Type': 'multipart/form-data'
    });
}


api.branchOrderList = (merchant_id, page, page_size) => {
    return service({
        url: PATH_MERCHANT_ORDER_LIST,
        method: 'GET',
        params: {
            merchant_id,
            page,
            page_size
        }
    });
}

api.userActivedList = (merchant_id, page, page_size, type, start_time, end_time) => {
    return service({
        url: PATH_USER_ACTIVED_LIST,
        method: 'GET',
        params: {
            merchant_id,
            page,
            page_size,
            type,
            start_time,
            end_time
        }
    });
}

api.userList = (merchant_id, page, page_size, order_filed, descend, search_filed) => {
    return service({
        url: PATH_USER_LIST,
        method: 'GET',
        params: {
            merchant_id,
            page,
            page_size,
            order_filed,
            descend,
            search_filed
        }
    });
}

api.statisticsInfo = (merchant_id, start_time, end_time) => {
    return service({
        url: PATH_STATISTICS_INFO,
        method: 'GET',
        params: {
            merchant_id,
            start_time,
            end_time
        }
    });
}

api.buildingList = (mobile, page, page_size) => {
    return service({
        url: PATH_BUILDING_LIST,
        method: 'GET',
        params: {
            mobile,
            page,
            page_size
        }
    });
}

api.twUserList = (merchant_id, page, page_size, search_filed) => {
    return service({
        url: PATH_TW_USER_LIST,
        method: 'GET',
        params: {
            merchant_id,
            page,
            page_size,
            search_filed
        }
    });
}

api.addTWUser = (username, password) => {
    return service({
        url: PATH_ADD_TW_USER,
        method: 'POST',
        data: {
            username,
            password
        }
    });
}

api.changeTWPWD = (username, password) => {
    return service({
        url: PATH_CHANGE_TW_USER_PWD,
        method: 'POST',
        data: {
            username,
            password
        }
    });
}


api.twActiveUser = (username) => {
    return service({
        url: PATH_TW_ACTIVE_USER,
        method: 'POST',
        data: {
            username
        }
    });
}

api.userActiveRecords = (user_id) => {
    return service({
        url: PATH_USER_ACTIVE_RECORDS,
        method: 'GET',
        params: {
            user_id
        }
    });
}


export default api
